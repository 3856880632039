:root {
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fce138;
  --blue-color: #024e76;
  --lightestpurple-color: #EBC2FB;
  --lightpurple-color: #7c4f91;
  --purple-color: #572175;
  --darkestpurple-color: #350a5b;
}

* {
  margin:0;
  padding:0;
  box-sizing:border-box;
}


a:link, a:visited {
  color: var(--black-color);
}

/* Header Styles */
header {
  padding: 0px 35px;
  height: 90px;
  background-color: var(--white-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: -webkit-sticky; /*always put vender-prefixed versions first */
  position: sticky;
  top: 0;
  /* background-image: url("../images/hero-bg.jpg"); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-position: 80%;
  z-index: 9999;
}

header h1 {
  font-weight:bold;
  font-size:36px;
  color:var(--white-color);
  margin:0;
  text-shadow: 0 0 10px rgba(248, 89, 200, 0.5);
}

h3 {
  padding-right: 10px;
}

header a {
  color:var(--black-color);
  text-decoration: none;
}


.logodiv {
  width: 30%;
  line-height: 90px;
}

.logotext {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
  
  
}

.linksdiv {
  width: 59%;
  align-items: middle;
  line-height: 90px;
}

.flowerdiv {
  width: 10%;
  line-height: 90px;
  position: relative;
}

.flowerimage {
  position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.homesectionpic {
  width: 100%;
  height: 600px;
}

.aboutussection {
  width: 100%;
  height: 600px;
  background-color: var(--black-color);
  color: #ffffff;
  padding: 25px;
}

.reviewsection {
  width: 100%;
  height: 550px;
  background-color: var(--black-color);
  color: #ffffff;
  padding: 25px;
}

.reviewsectiontext {
  float: left;
  width: 25%;
  height: 275px;
  background-color: var(--black-color);
  color: #ffffff;
  padding-top: 25px;
  padding-right: 25px;
  font-size: 18px;
  text-align: center;
}

.mainlinktitle {
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding-top: 5px;
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  border: 2px solid #B0B0B0;

}

.serviceslinktitle {
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding-top: 5px;
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 14px;

}

.leftsectionpicdark {
  float: left;
  width: 40%;
  height: 500px;
  background-color: var(--black-color);
  color: #000000;
  margin: 0px;
  text-align: center;
  padding-top: 25px;

}

.leftsectionpicdarkgoals {
  float: left;
  width: 40%;
  height: 500px;
  background-color: var(--black-color);
  color: #000000;
  margin: 0px;
  text-align: center;
  padding-top: 25px;
  

}

.leftsectionpicdarkgoalslast {
  float: left;
  width: 40%;
  height: 500px;
  background-color: var(--black-color);
  color: #000000;
  margin: 0px;
  text-align: center;
  
  

}

.leftsectionpicdarkabout {
  float: left;
  width: 40%;
  height: 300px;
  background-color: var(--black-color);
  color: #000000;
  margin: 0px;
  text-align: center;
  padding-top: 25px;

}

.leftsectionpicdarkbehind {
  float: left;
  width: 50%;
  height: 700px;
  background-color: var(--black-color);
  color: #000000;
  margin-bottom: 50px;
  text-align: center;
  padding-top: 25px;

}

.sectionpicdarkport {
  float: left;
  width: 33%;
  height: 685px;
  background-color: var(--black-color);
  color: #ffffff;
  margin: 0px;
  text-align: center;
  padding-top: 25px;
  text-wrap: wrap;

}



.sectionpicdarkportsliver {
  float: left;
  width: 1%;
  height: 685px;
  background-color: var(--black-color);
  color: #ffffff;
  margin: 0px;
  text-align: center;
  padding-top: 25px;

}


.portfoliodesc {
  width: 90%;
  height: 100px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-left: 25px;
  text-wrap: wrap;
  margin-top: -15px;
  

  
}

.footersection {
  float: left;
  width: 25%;
  height: 150px;
  color: #000000;
  text-align: center;
  padding-bottom: 25px;
  margin-top: -10px;
}


.rightsection {
  float: left;
  width: 50%;
  height: 600px;
  background-color: var(--white-color);
  color: #000000;
  padding: 0px;
  margin: 0px;
}

.rightsectiondark {
  float: left;
  width: 60%;
  height: 500px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
}

.rightsectiondarkgoals {
  float: left;
  width: 100%;
  height: 500px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
}

.rightsectiondarkgoalslast {
  float: left;
  width: 60%;
  height: 500px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
  
}

.rightsectiondarkabout {
  float: left;
  width: 60%;
  height: 300px;
  background-color: #000000;
  color: #ffffff;
  margin: 0px;
}

.rightsectiondarkbehind {
  float: left;
  width: 50%;
  height: 700px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin-bottom: 50px;
}

.servicessectiondark {
  float: left;
  width: 50%;
  height: 500px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
}

.servicessectiondarktop {
  float: left;
  width: 50%;
  height: 425px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
}

.servicessectiondarkbottom {
  float: left;
  width: 50%;
  height: 375px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  margin: 0px;
}


.servicessectiondarktype {
  float: left;
  width: 55%;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  margin: 0px;
  padding-top: 10px;
}

.servicessectiondarkprice {
  float: left;
  width: 35%;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  padding-left: -20px;
  padding-top: 10px;
  margin: 0px;
}

.moveright {
  padding-left: 75px;
}

.summertitle {
  width: 25%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  font-size: 14px;
  border: 2px solid #ffffff;
  text-align: center;

  
}

.portfoliotitle {
  width: 90%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  border: 2px solid #ffffff;
  text-align: center;
  margin-left: 30px;

  
}






.footer {
  color: var(--black-color);
  background-color: var(--white-color);
  height: 300px;
  width: 100%;
  float: left;
  padding: 10px;
  padding-bottom: 25px;
}


main {
	margin: 0px;
	max-width: 100%;
  padding: 0px;
}
:root {
  --primary: #8c54ff;
  --secondary: #F3A847;
  --tertiary: #B12704;
  --light: #fafafa;
  --dark: #000000;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: var(--white-color);
  color: var(--black-color);           
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--tertiary);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.bullet {
  list-style-type:disc #ffffff;

}


header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

li {
  list-style: none;
}

nav {
  background-color: var(--white-color);
}

nav a:hover {
  text-decoration: underline;
}

nav  li {
  cursor: pointer;
}

textarea {
	width: 600px;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

button {
	background: var(--primary);
	border: 0;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;	
}

a {
	border-radius: 5px;
	color: var(--primary);
	font-weight: 600;
	padding: 2px 4px;
	margin-left: -2px;
	margin-right: -2px;
	
}

.navActive {
  color: #000000;
}

.navHover {
  color: #B0B0B0;
  text-decoration: underline;
}

.project-title {
  font-size: 30px;
  color: var(--yellow-color)
}

.proficient-title {
  color: var(--yellow-color)
}
